
import { defineComponent, ref } from "vue";
import {
  translate,
} from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params);

    return {
      value,
      translate
    };
  },
});
