<template>
  <div v-show="showRound && selectedSuppliers.length > 0">
    <TableSkeleton v-show="loading" />
    <AgGridFP
      v-show="!loading"
      id="table1"
      ag-theme="ag-theme-alpine"
      widget-classes="card-xxl-stretch mb-xl-3"
      :aggrid-data="roundsData"
      :enable-card-header="true"
      :custom-column-defs="customColumnDefs"
      :custom-body-options="bodyOptions"
      :enable-row-group="false"
      :enable-search="false"
      :enableStatusBar="true"
    >
      <template v-slot:toolbar-content>
        <div class="d-flex flex-column-reverse">
          <button
            :disabled="selectedUsers.length === 0"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_notify_users"
            type="button"
          >{{ translate('rfq.rounds.notifyUserBtnText') }}</button>

          <div>
            <span class="svg-icon svg-icon-2x svg-icon-info">
              <inline-svg src="media/icons/duotone/Code/Info-circle.svg" />
            </span>
            <span>{{ translate('rfq.rounds.notifyUserInfoText') }}</span>
          </div>
        </div>
        <button
          class="btn btn-warning"
          type="button"
          @click="showRound = false"
        >{{ translate('rfq.rounds.notifyUserChangeSupplier') }}</button>
        <EmailUsersVue :email-list="selectedUsers" />
      </template>
    </AgGridFP>
  </div>
  <div v-show="!showRound">
    <!--begin::Filter bar-->
    <div class="d-flex flex-wrap flex-sm-column-fluid">
      <div class="d-flex flex-wrap align-items-center me-3 border-end-right">
        <inline-svg src="media/icons/duotone/Shopping/Sort1.svg" />
        <h3 class="fw-bolder me-5 my-2">{{ translate('rfq.rounds.filter') }}</h3>
      </div>

      <div class="d-flex flex-column">
        <div class="d-flex flex-wrap mb-3">
          <!--begin::Select Type-->
          <Multiselect
            class="w-100 w-md-175px m-0 custom__multiselect"
            v-bind="selectSupplierTagMultiselect"
            v-model="selectSupplierTagMultiselect.value"
            tabindex="-1"
          >
            <template v-slot:caret>
              <div class="multiselect-caret custom__caret"></div>
            </template>
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ translate('rfq.rounds.tag') }}
                <span
                  class="badge badge-circle badge-info ms-2"
                >{{ values.length }}</span>
              </div>
            </template>
          </Multiselect>
          <!--end::Select Type -->

          <!--begin::Select Company-->
          <Multiselect
            class="w-100 w-md-175px m-0 ms-5 me-0 my-md-0 ms-md-5 custom__multiselect"
            v-bind="selectSupplierCompanyMultiselect"
            v-model="selectSupplierCompanyMultiselect.value"
            tabindex="-1"
          >
            <template v-slot:caret>
              <div class="multiselect-caret custom__caret"></div>
            </template>
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ translate('rfq.rounds.company') }}
                <span
                  class="badge badge-circle badge-info ms-2"
                >{{ values.length }}</span>
              </div>
            </template>
          </Multiselect>
          <!--end::Select Company -->

          <!--begin::Select Rating-->
          <Multiselect
            class="w-100 w-md-175px m-0 ms-5 me-0 my-md-0 ms-md-5 custom__multiselect"
            v-bind="selectSupplierRatingMultiselect"
            v-model="selectSupplierRatingMultiselect.value"
            tabindex="-1"
          >
            <template v-slot:caret>
              <div class="multiselect-caret custom__caret"></div>
            </template>
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ translate('rfq.rounds.rating') }}
                <span
                  class="badge badge-circle badge-info ms-2"
                >{{ values.length }}</span>
              </div>
            </template>
          </Multiselect>
          <!--end::Select Rating -->
        </div>
        <div class="d-flex flex-column">
          <label
            for="rfqDescription"
            class="required form-label"
          >{{ translate('rfq.rounds.address') }}</label>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Search addresses..."
            v-model="address"
          />
        </div>
      </div>

      <div
        class="d-flex flex-column flex-column-fluid flex-md-column-auto justify-content-between ms-0 mt-3 ms-md-3 mt-md-0"
      >
        <button
          class="btn btn-primary"
          @click="handleFilterApply"
        >{{ translate('rfq.rounds.applyFilter') }}</button>
        <button
          class="btn btn-secondary mt-3 mt-md-0"
          @click="handleClearFilters"
        >{{ translate('rfq.rounds.clearFilter') }}</button>
      </div>
    </div>
    <!--end::Filter bar-->

    <!--begin::Separator-->
    <div class="separator border-1 my-4"></div>
    <!--end::Separator-->

    <!--begin::Filter tag bar-->
    <div v-if="filterArrayValues.length >= 1" class="filter__tagBar">
      <div class="d-flex flex-wrap tag__wrapper">
        <ElTag
          v-for="filter in filterArrayValues"
          :key="filter.label"
          class="me-5 mb-3"
          @close="handleTagClose(filter)"
        >
          <strong>{{ filter.groupLabel }}</strong>
          {{ filter.label }}
        </ElTag>
        <a
          class="text-decoration-underline pe-auto"
          style="line-height: 30px; cursor: pointer"
          @click="handleClearFilters"
        >Clear All</a>
      </div>
    </div>
    <!--end::Filter tag bar-->

    <TableSkeleton v-show="selectSupplierLoading" />
    <AgGridFP
      v-show="!selectSupplierLoading"
      id="table1"
      ag-theme="ag-theme-alpine"
      widget-classes="card-xxl-stretch mb-xl-3"
      :aggrid-data="flattenSupplierList"
      :enable-card-header="true"
      :custom-column-defs="selectSupplierCustomColumnDefs"
      :custom-body-options="selectSupplierBodyOptions"
      :enable-row-group="false"
      :enable-search="false"
      :enableStatusBar="true"
    >
      <template v-slot:toolbar-content>
        <div class="d-flex flex-column-reverse">
          <button
            :disabled="selectedSuppliers.length === 0"
            class="btn btn-primary"
            type="button"
            @click="showRound = !showRound"
          >{{ translate('rfq.rounds.selectUserBtnText') }}</button>
          <div>
            <span class="svg-icon svg-icon-2x svg-icon-info">
              <inline-svg src="media/icons/duotone/Code/Info-circle.svg" />
            </span>
            <span>{{ translate('rfq.rounds.selectUserInfoText') }}</span>
          </div>
        </div>
        <EmailUsersVue :email-list="selectedUsers" />
      </template>
    </AgGridFP>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import { RoundSetup } from "./Rounds";
import { SelectSupplierSetup } from "./SelectSupplier";
import { ElTag } from 'element-plus';

import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import EmailUsersVue from "@/components/modals/general/EmailUsers.vue";
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: "RFQ Rounds",
  components: {
    ElTag,
    AgGridFP,
    TableSkeleton,
    EmailUsersVue,
    Multiselect
  },
  setup() {
    const showRound = ref(false);

    const {
      address,
      tagRef,
      ratingRef,
      companyRef,
      handleTagClose,
      handleFilterApply,
      filterArrayValues,
      handleClearFilters,
      supplierList,
      selectedSuppliers,
      flattenSupplierList,
      selectSupplierGridApi,
      selectSupplierColApi,
      selectSupplierLoading,
      selectSupplierBodyOptions,
      selectSupplierCustomColumnDefs,
      selectSupplierHandleSupplierSelection,
      selectSupplierComponentKey,
      selectSupplierTagMultiselect,
      selectSupplierRatingMultiselect,
      selectSupplierCompanyMultiselect,
    } = SelectSupplierSetup();

    const {
      gridApi,
      colApi,
      loading,
      translate,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      roundsData,
      selectedUsers
    } = RoundSetup();

    watchEffect(() => {
      if (showRound.value) {
        roundsData.value = selectedSuppliers.value
      }
    })

    return {
      address,
      tagRef,
      translate,
      ratingRef,
      showRound,
      companyRef,
      handleTagClose,
      handleFilterApply,
      filterArrayValues,
      handleClearFilters,
      selectedSuppliers,
      flattenSupplierList,
      selectSupplierTagMultiselect,
      selectSupplierRatingMultiselect,
      selectSupplierCompanyMultiselect,
      supplierList,
      selectSupplierGridApi,
      selectSupplierColApi,
      selectSupplierLoading,
      selectSupplierBodyOptions,
      selectSupplierCustomColumnDefs,
      selectSupplierHandleSupplierSelection,
      selectSupplierComponentKey,

      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      roundsData,
      selectedUsers
    }
  }
});
</script>
