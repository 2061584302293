
import { defineComponent, ref, watchEffect } from "vue";
import { RoundSetup } from "./Rounds";
import { SelectSupplierSetup } from "./SelectSupplier";
import { ElTag } from 'element-plus';

import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import EmailUsersVue from "@/components/modals/general/EmailUsers.vue";
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: "RFQ Rounds",
  components: {
    ElTag,
    AgGridFP,
    TableSkeleton,
    EmailUsersVue,
    Multiselect
  },
  setup() {
    const showRound = ref(false);

    const {
      address,
      tagRef,
      ratingRef,
      companyRef,
      handleTagClose,
      handleFilterApply,
      filterArrayValues,
      handleClearFilters,
      supplierList,
      selectedSuppliers,
      flattenSupplierList,
      selectSupplierGridApi,
      selectSupplierColApi,
      selectSupplierLoading,
      selectSupplierBodyOptions,
      selectSupplierCustomColumnDefs,
      selectSupplierHandleSupplierSelection,
      selectSupplierComponentKey,
      selectSupplierTagMultiselect,
      selectSupplierRatingMultiselect,
      selectSupplierCompanyMultiselect,
    } = SelectSupplierSetup();

    const {
      gridApi,
      colApi,
      loading,
      translate,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      roundsData,
      selectedUsers
    } = RoundSetup();

    watchEffect(() => {
      if (showRound.value) {
        roundsData.value = selectedSuppliers.value
      }
    })

    return {
      address,
      tagRef,
      translate,
      ratingRef,
      showRound,
      companyRef,
      handleTagClose,
      handleFilterApply,
      filterArrayValues,
      handleClearFilters,
      selectedSuppliers,
      flattenSupplierList,
      selectSupplierTagMultiselect,
      selectSupplierRatingMultiselect,
      selectSupplierCompanyMultiselect,
      supplierList,
      selectSupplierGridApi,
      selectSupplierColApi,
      selectSupplierLoading,
      selectSupplierBodyOptions,
      selectSupplierCustomColumnDefs,
      selectSupplierHandleSupplierSelection,
      selectSupplierComponentKey,

      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      roundsData,
      selectedUsers
    }
  }
});
