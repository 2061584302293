
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Chip",
  emits: ['delete-chip'],
  props: {
    chipId: [String, Number],
    imgSrc: String,
    chipText: String,
    chipAlt: String,
    enableClosable: Boolean
  },
});
