import { ColDef, GridApi, ColumnApi, GridOptions } from "ag-grid-community";

import { ref, onMounted, computed, reactive, toRefs, watchEffect } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import {
  formatNumbers,
  formatDates,
  translate,
  currentLang,
  setDateFormat,
} from "@/core/helpers/config";

import AgGridLink2 from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridLink2.vue";

export const RoundSetup = () => {
  const roundsData = ref<any[]>([]);
  const store = useStore();
  const loading = ref(true);
  const gridApi = ref<GridApi>();
  const colApi = ref<ColumnApi>();
  const selectedUsers = ref<any[]>([]);
  const componentKey = computed(() => store.state.BodyModule.componentKey);

  const customColumnDefs = ref<ColDef[]>([
    {
      hide: false,
      editable: false,
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      suppressMenu: true,
      lockPosition: true,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      field: "id",
      hide: true,
      editable: false,
    },
    {
      field: "supplier",
      headerName: translate("rfq.supplier"),
      editable: false,
      cellRendererFramework: AgGridLink2,
      cellRendererParams: {
        routeName: "rounds",
      },
    },
    {
      field: "email",
      headerName: translate("rfq.rounds.email"),
      maxWidth: 250,
      editable: false,
    },
    {
      field: "status",
      headerName: translate("rfq.rounds.status"),
      maxWidth: 130,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return "not_started";
        return params.value;
      },
    },
    {
      field: "date",
      headerName: translate("rfq.rounds.emailOn"),
      maxWidth: 200,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return "00.00.0000";
        return formatDates(params.value, "short", currentLang.value);
      },
    },
    {
      field: "round",
      headerName: translate("rfq.rounds.round"),
      maxWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return `${translate("rfq.rounds.round")} 1`;
        return params.value;
      },
    },
  ]);
  /* eslint-disable @typescript-eslint/class-name-casing */
  /* eslint-disable @typescript-eslint/camelcase */

  const handleSupplierSelection = (evt) => {
    const getSelectedRow = evt.api.getSelectedRows();
    selectedUsers.value = getSelectedRow;
  };

  const bodyOptions = reactive<GridOptions>({
    editType: "",
    rowSelection: "multiple",
    enableRangeSelection: false,
    enableFillHandle: false,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    onSelectionChanged: handleSupplierSelection,
    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
  });

  onMounted(() => {
    setTimeout(() => {
      console.log("mounted");
      // roundsData.value = supplierList;
      loading.value = false;
    }, 1500);
  });

  return {
    gridApi,
    colApi,
    loading,
    translate,
    bodyOptions,
    setDateFormat,
    customColumnDefs,
    handleSupplierSelection,
    componentKey,
    currentLang,
    roundsData,
    selectedUsers,
  };
};
