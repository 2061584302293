<template>
  <div
    class="chip border border-info border-1 text-white bg-info bg-gradient my-1 mx-1"
    :title="chipAlt"
  >
    <img :src="imgSrc" :alt="chipAlt" width="96" height="96" v-if="imgSrc" />
    <span class="chip__content">{{ chipText }}</span>
    <span class="closebtn" @click="$emit('delete-chip', chipId)" v-if="enableClosable">&times;</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Chip",
  emits: ['delete-chip'],
  props: {
    chipId: [String, Number],
    imgSrc: String,
    chipText: String,
    chipAlt: String,
    enableClosable: Boolean
  },
});
</script>

<style>
.chip {
  border-radius: 16px;
  align-items: center;
  cursor: default;
  display: inline-flex;
  line-height: 20px;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 14px;
}

.chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
}

.chip img {
  float: left;
  margin: 0 10px 0 -25px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}
</style>