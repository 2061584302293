
import { defineComponent, ref, reactive, toRefs, onMounted, onUnmounted, PropType } from "vue";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElMessageBox, ElMessage } from "element-plus";
import { translate } from "@/core/helpers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import Chip from "@/components/chips/Chip.vue"

interface EmailList {
  id: number,
  round: string,
  supplier: string,
  email: string,
  date: string | Date,
  status: string
}

interface EmailData {
  email: any,
  emailMessage: string
}

export default defineComponent({
  name: "notify-users-modal",
  props: {
    emailList: Array as PropType<EmailList[]>,
  },
  components: {
    ErrorMessage,
    Field,
    Chip,
    Form
  },
  setup(props, { emit }) {
    const modal = ref<null | HTMLElement>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const emailData = reactive<EmailData>({
      email: '',
      emailMessage: '',
    })

    const validationSchema = Yup.object().shape({
      emailMessage: Yup.string()
        .required()
        .label("Email Message"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ElMessageBox.confirm(
        `${translate("rfq.rounds.notifyUserModalConfirmBox1")} ${props.emailList!.length} ${translate("rfq.rounds.notifyUserModalConfirmBox2")}`,
        `${translate("rfq.rounds.notifyUserModalConfirmBoxTitle")}`,
        {
          confirmButtonText: `${translate("rfq.rounds.notifyUserModalConfirmBoxSend")}`,
          cancelButtonText: `${translate("rfq.rounds.notifyUserModalConfirmBoxCancel")}`,
          type: 'warning',
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: `${translate("rfq.rounds.notifyUserModalConfirmBoxSuccess")}`,
          })
          setTimeout(() => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            }).then(() => {
              window.location.reload();
            });
          }, 2000);

        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Cancel',
          })
        })
    };

    onMounted(() => {
      modal.value?.addEventListener('shown.bs.modal', () => {
        const emailList = props.emailList;
        emailData.email = emailList;
      })

      modal.value?.addEventListener('hidden.bs.modal', () => {
        removeModalBackdrop();
      })
    });

    onUnmounted(() => {
      modal.value?.removeEventListener('shown.bs.modal', () => {
        removeModalBackdrop();
      })
      modal.value?.removeEventListener('hidden.bs.modal', () => {
        removeModalBackdrop();
      })
    })

    return {
      ...toRefs(emailData),
      modal,
      validationSchema,
      submit,
      submitButtonRef,
      translate
    };
  }
});
