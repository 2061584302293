<template>
  <router-link :to="{ name: `${value.routeName}` }">{{ value.value }}</router-link>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  translate,
} from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params);

    return {
      value,
      translate
    };
  },
});
</script>

<style scoped>
.btn {
  z-index: 9999;
}
</style>