<template>
  <!--begin::Modal - notify users-->
  <div class="modal fade" id="kt_modal_notify_users" tabindex="-1" aria-hidden="true" ref="modal">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
          class="form"
          id="kt_modal_notify_users"
          @submit="submit"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_notify_users_header">
            <!--begin::Modal title-->
            <h2>{{ translate("rfq.rounds.notifyUserModalTitle") }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              id="kt_modal_notify_users_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_notify_users_header"
              data-kt-scroll-wrappers="#kt_modal_notify_users_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div class="row mb-5">
                <!--begin::Col-->
                <div class="col-md-12 fv-row">
                  <!--begin::Label-->
                  <label
                    class="required fs-5 fw-bold mb-2"
                  >{{ translate("rfq.rounds.notifyUserModalTo") }}:</label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <div class="border border-1">
                    <Chip
                      v-for="e in email"
                      :key="e.id"
                      :chip-alt="e.email"
                      :chip-id="e.id"
                      :chip-text="e.supplier"
                      :enable-closable="false"
                    ></Chip>
                  </div>
                  <!-- 
                  <Field type="text" name="email" v-model="email" v-slot="{ field }">
                    <input
                      v-bind="field"
                      type="text"
                      class="form-control form-control-solid"
                      disabled
                    />
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="email" />
                    </div>
                  </div>-->
                  <!--end::Input-->
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-md-12 fv-row py-3">
                  <!--end::Label-->
                  <label
                    class="required fs-5 fw-bold mb-2"
                  >{{ translate("rfq.rounds.notifyUserModalEmailMsg") }}</label>
                  <!--end::Label-->

                  <!--end::Input-->
                  <Field
                    type="text"
                    class="form-control form-control-solid"
                    placeholder
                    name="emailMessage"
                    v-model="emailMessage"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form-control min-h-150px"
                      data-kt-autosize="true"
                      rows="1"
                      placeholder="Enter Description of RFQ.."
                    ></textarea>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="emailMessage" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              type="button"
              data-bs-dismiss="modal"
              id="kt_modal_notify_users_cancel"
              class="btn btn-white me-3"
            >{{ translate("rfq.rounds.notifyUserModalCancel") }}</button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              ref="submitButtonRef"
              type="submit"
              id="kt_modal_notify_users_submit"
              class="btn btn-primary"
              :disabled="!email || !emailMessage"
            >
              <span class="indicator-label">{{ translate("rfq.rounds.notifyUserModalSubmit") }}</span>
              <span class="indicator-progress">
                {{ translate("rfq.rounds.notifyUserModalPleaseWait") }}...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Modal - New Address-->
</template>

<script lang="ts">
import { defineComponent, ref, reactive, toRefs, onMounted, onUnmounted, PropType } from "vue";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElMessageBox, ElMessage } from "element-plus";
import { translate } from "@/core/helpers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import Chip from "@/components/chips/Chip.vue"

interface EmailList {
  id: number,
  round: string,
  supplier: string,
  email: string,
  date: string | Date,
  status: string
}

interface EmailData {
  email: any,
  emailMessage: string
}

export default defineComponent({
  name: "notify-users-modal",
  props: {
    emailList: Array as PropType<EmailList[]>,
  },
  components: {
    ErrorMessage,
    Field,
    Chip,
    Form
  },
  setup(props, { emit }) {
    const modal = ref<null | HTMLElement>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const emailData = reactive<EmailData>({
      email: '',
      emailMessage: '',
    })

    const validationSchema = Yup.object().shape({
      emailMessage: Yup.string()
        .required()
        .label("Email Message"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ElMessageBox.confirm(
        `${translate("rfq.rounds.notifyUserModalConfirmBox1")} ${props.emailList!.length} ${translate("rfq.rounds.notifyUserModalConfirmBox2")}`,
        `${translate("rfq.rounds.notifyUserModalConfirmBoxTitle")}`,
        {
          confirmButtonText: `${translate("rfq.rounds.notifyUserModalConfirmBoxSend")}`,
          cancelButtonText: `${translate("rfq.rounds.notifyUserModalConfirmBoxCancel")}`,
          type: 'warning',
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: `${translate("rfq.rounds.notifyUserModalConfirmBoxSuccess")}`,
          })
          setTimeout(() => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            }).then(() => {
              window.location.reload();
            });
          }, 2000);

        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Cancel',
          })
        })
    };

    onMounted(() => {
      modal.value?.addEventListener('shown.bs.modal', () => {
        const emailList = props.emailList;
        emailData.email = emailList;
      })

      modal.value?.addEventListener('hidden.bs.modal', () => {
        removeModalBackdrop();
      })
    });

    onUnmounted(() => {
      modal.value?.removeEventListener('shown.bs.modal', () => {
        removeModalBackdrop();
      })
      modal.value?.removeEventListener('hidden.bs.modal', () => {
        removeModalBackdrop();
      })
    })

    return {
      ...toRefs(emailData),
      modal,
      validationSchema,
      submit,
      submitButtonRef,
      translate
    };
  }
});
</script>