import SUPPLIER_LIST from "@/assets/json/supplierList.json";

import { ColDef, GridApi, ColumnApi, GridOptions } from "ag-grid-community";

import { ref, onMounted, computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import {
  formatNumbers,
  formatDates,
  translate,
  currentLang,
  setDateFormat,
} from "@/core/helpers/config";
import { getUniqueIdWithPrefix } from "@/assets/ts/_utils/helpers/types-helpers/_getUniqueIdWithPrefix";

interface DropdownValues {
  value?: string | number;
  label?: string;
  groupLabel?: string;
}

export const SelectSupplierSetup = () => {
  const tagRef = ref();
  const companyRef = ref();
  const ratingRef = ref();

  const supplierList = ref<any[]>([]);
  const flattenSupplierList = ref<any[]>([]);
  const store = useStore();
  const address = ref("");
  const selectSupplierLoading = ref(true);
  const selectSupplierGridApi = ref<GridApi>();
  const selectSupplierColApi = ref<ColumnApi>();
  const selectedSuppliers = ref<any[]>([]);
  const selectSupplierCustomColumnDefs = ref<ColDef[]>([
    {
      hide: false,
      editable: false,
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      suppressMenu: true,
      lockPosition: true,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      field: "id",
      hide: true,
      editable: false,
    },
    {
      field: "userId",
      hide: true,
      editable: false,
    },
    {
      field: "supplier_email",
      headerName: translate("rfq.supplierEmail"),
      maxWidth: 350,
      editable: false,
      aggFunc: "first",
      suppressMenu: true,
    },
    {
      field: "rating",
      headerName: translate("rfq.rounds.rating"),
      maxWidth: 200,
      editable: false,
      aggFunc: "first",
      suppressMenu: true,
      filter: "agNumberColumnFilter",
      filterParams: { readOnly: true },
    },
    {
      field: "type",
      headerName: translate("rfq.rounds.tag"),
      maxWidth: 150,
      editable: false,
      aggFunc: "first",
      filter: "agSetColumnFilter",
      suppressMenu: true,
    },
    {
      field: "address",
      headerName: translate("rfq.rounds.address"),
      editable: false,
      aggFunc: "first",
      suppressMenu: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "name",
      headerName: translate("rfq.name"),
      editable: false,
      suppressMenu: true,
    },
    {
      field: "email",
      headerName: translate("rfq.rounds.email"),
      editable: false,
      suppressMenu: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      editable: false,
      rowGroup: true,
      hide: true,
      filter: "agSetColumnFilter",
    },
  ]);

  const selectSupplierTagMultiselect = reactive({
    mode: "multiple",
    placeholder: translate("rfq.rounds.tag"),
    closeOnSelect: false,
    object: true,
    value: [] as DropdownValues[],
    options: [] as DropdownValues[],
    trackBy: "value",
    label: "label",
  });

  const selectSupplierCompanyMultiselect = reactive({
    mode: "multiple",
    placeholder: translate("rfq.rounds.company"),
    closeOnSelect: false,
    object: true,
    value: [] as DropdownValues[],
    options: [] as DropdownValues[],
    trackBy: "value",
    label: "label",
    searchable: true,
  });

  const selectSupplierRatingMultiselect = reactive({
    mode: "single",
    placeholder: translate("rfq.rounds.rating"),
    closeOnSelect: false,
    object: true,
    value: [] as any,
    options: [
      {
        value: 10,
        label: `${translate("rfq.rounds.greaterThan")} 10`,
        groupLabel: "supplier_rating",
      },
      {
        value: 20,
        label: `${translate("rfq.rounds.greaterThan")} 20`,
        groupLabel: "supplier_rating",
      },
      {
        value: 30,
        label: `${translate("rfq.rounds.greaterThan")} 30`,
        groupLabel: "supplier_rating",
      },
      {
        value: 40,
        label: `${translate("rfq.rounds.greaterThan")} 40`,
        groupLabel: "supplier_rating",
      },
      {
        value: 50,
        label: `${translate("rfq.rounds.greaterThan")} 50`,
        groupLabel: "supplier_rating",
      },
      {
        value: 60,
        label: `${translate("rfq.rounds.greaterThan")} 60`,
        groupLabel: "supplier_rating",
      },
      {
        value: 70,
        label: `${translate("rfq.rounds.greaterThan")} 70`,
        groupLabel: "supplier_rating",
      },
      {
        value: 80,
        label: `${translate("rfq.rounds.greaterThan")} 80`,
        groupLabel: "supplier_rating",
      },
      {
        value: 90,
        label: `${translate("rfq.rounds.greaterThan")} 90`,
        groupLabel: "supplier_rating",
      },
    ] as DropdownValues[],
    trackBy: "value",
    label: "label",
  });

  const selectSupplierComponentKey = computed(
    () => store.state.BodyModule.componentKey
  );

  const filterArrayValues = computed(() => {
    return Array.prototype.concat(
      selectSupplierTagMultiselect.value,
      selectSupplierCompanyMultiselect.value,
      selectSupplierRatingMultiselect.value
    );
  });

  /* eslint-disable @typescript-eslint/class-name-casing */
  /* eslint-disable @typescript-eslint/camelcase */

  const selectSupplierHandleSupplierSelection = (evt) => {
    const getSelectedRow = evt.api.getSelectedRows();
    selectedSuppliers.value = getSelectedRow;
  };

  const selectSupplierBodyOptions = reactive<GridOptions>({
    editType: "",
    rowSelection: "multiple",
    enableRangeSelection: false,
    enableFillHandle: false,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    onSelectionChanged: selectSupplierHandleSupplierSelection,
    onGridReady: (params) => {
      selectSupplierGridApi.value = params.api;
      selectSupplierColApi.value = params.columnApi;
    },
    autoGroupColumnDef: {
      headerName: translate("rfq.rounds.group"),
      minWidth: 220,
      maxWidth: 200,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: [],
    },
    // optional as 'singleColumn' is the default group display type
    groupDisplayType: "singleColumn",
    groupSelectsChildren: true,
    groupSelectsFiltered: true,
    // set this to true to remove single children
    groupRemoveSingleChildren: false,

    // set this to true to remove leaf level single children
    groupRemoveLowestSingleChildren: false,

    suppressAggFuncInHeader: true,
  });

  const flattenArray = () => {
    const suppliers = supplierList.value.flatMap((supplier) => {
      return [
        ...supplier.contacts.flatMap((contact) => {
          return [
            {
              id: getUniqueIdWithPrefix("row"),
              supplier: supplier.supplier,
              address: supplier.address,
              supplier_email: supplier["supplier_email"],
              rating: supplier["rating"],
              type: supplier["type"],
              userId: getUniqueIdWithPrefix("user"),
              name: contact.name,
              email: contact.email,
            },
          ];
        }),
      ];
    });
    return suppliers;
  };

  const populateTypeMultiselect = () => {
    const typeArray = [
      ...new Set(flattenSupplierList.value.map((supplier) => supplier.type)),
    ];
    const filterTypeValues = typeArray.map((type) => ({
      value: type,
      label: translate(type),
      groupLabel: "supplier_type",
    }));
    selectSupplierTagMultiselect.options = filterTypeValues;
  };

  const populateCompanyMultiselect = () => {
    const companyArray = [
      ...new Set(
        flattenSupplierList.value.map((supplier) => supplier.supplier)
      ),
    ];
    const filterCompanyValues = companyArray.map((company) => ({
      value: company,
      label: translate(company),
      groupLabel: "supplier_company",
    }));
    selectSupplierCompanyMultiselect.options = filterCompanyValues;
  };

  const filterAgGridType = () => {
    const typeValues = filterArrayValues.value
      .map((x) => {
        if (x.groupLabel === "supplier_type") return x.value;
      })
      .filter((v) => v !== undefined);

    if (typeValues[0] === "undefined") return;

    const instance = selectSupplierGridApi.value?.getFilterInstance("type");
    instance!.setModel({
      values: typeValues,
    });
  };

  const filterAgGridCompany = () => {
    const companyValues = filterArrayValues.value
      .map((x) => {
        if (x.groupLabel === "supplier_company") return x.value;
      })
      .filter((v) => v !== undefined);

    if (companyValues.length === 0) return;
    const instance = selectSupplierGridApi.value!.getFilterInstance("supplier");
    instance!.setModel({
      values: companyValues,
    });
  };

  const filterAgGridRating = () => {
    const ratingValues = filterArrayValues.value
      .map((x) => {
        if (x.groupLabel === "supplier_rating") return x.value;
      })
      .filter((rating) => rating !== undefined);

    if (ratingValues[0] === "undefined") return;

    const ratingFilterComponent = selectSupplierGridApi.value!.getFilterInstance(
      "rating"
    );

    ratingFilterComponent!.setModel({
      type: "greaterThan",
      filter: ratingValues[0],
      filterTo: null,
    });
  };

  const filterAddress = () => {
    const addressFilterComponent = selectSupplierGridApi.value!.getFilterInstance(
      "address"
    );

    addressFilterComponent?.setModel({
      filterType: "text",
      type: "contains",
      filter: address.value,
    });
  };

  const handleFilterApply = () => {
    if (filterArrayValues.value.length === 0) {
      return flattenArray();
    }

    if (selectSupplierTagMultiselect.value.length !== 0) {
      filterAgGridType();
    }

    if (selectSupplierCompanyMultiselect.value.length !== 0) {
      filterAgGridCompany();
    }

    if (selectSupplierRatingMultiselect.value.length !== 0) {
      filterAgGridRating();
    }

    if (address.value !== "") {
      filterAddress();
    }

    selectSupplierGridApi.value!.onFilterChanged();
    // const groupLabel = filterArrayValues.value.map((x) => x.groupLabel);
    // const groupLabelNoDupes = [...new Set(groupLabel)];
    // let supplierCopy = flattenSupplierList.value;

    // groupLabelNoDupes.forEach((obj) => {
    //   if (obj === "supplier_type") {
    //     supplierCopy = supplierCopy.filter((supplier) => {
    //       return selectSupplierTagMultiselect.value.some((f) => {
    //         return f.value === supplier.type;
    //       });
    //     });
    //   } else if (obj === "supplier_company") {
    //     supplierCopy = supplierCopy.filter((supplier) => {
    //       return selectSupplierCompanyMultiselect.value.some((f) => {
    //         return f.value === supplier.company;
    //       });
    //     });
    //   } else if (obj === "supplier_rating") {
    //     supplierCopy = supplierCopy.filter((supplier) => {
    //       return selectSupplierRatingMultiselect.value.value <= supplier.rating;
    //     });
    //   }
    // });
  };

  const handleTagClose = (tag) => {
    filterArrayValues.value.splice(filterArrayValues.value.indexOf(tag), 1);
    const filterModel = selectSupplierGridApi.value!.getFilterModel();
    console.log(filterModel);
    if (
      selectSupplierTagMultiselect.value.length > 0 &&
      selectSupplierTagMultiselect.value.indexOf(tag) !== -1
    ) {
      selectSupplierTagMultiselect.value.splice(
        selectSupplierTagMultiselect.value.indexOf(tag),
        1
      );
      // filteredContracts.value = CONTRACTS.slice();
      handleFilterApply();

      return;
    }
    if (
      selectSupplierCompanyMultiselect.value.length > 0 &&
      selectSupplierCompanyMultiselect.value.indexOf(tag) !== -1
    ) {
      selectSupplierCompanyMultiselect.value.splice(
        selectSupplierCompanyMultiselect.value.indexOf(tag),
        1
      );
      // filteredContracts.value = CONTRACTS.slice();
      handleFilterApply();

      return;
    }
    if (selectSupplierRatingMultiselect.value !== null) {
      selectSupplierRatingMultiselect.value = [];
      // filteredContracts.value = CONTRACTS.slice();
      handleFilterApply();

      return;
    }
  };

  const handleClearFilters = () => {
    selectSupplierTagMultiselect.value = [];
    selectSupplierCompanyMultiselect.value = [];
    selectSupplierRatingMultiselect.value = [];

    // const typeFilterComponent = selectSupplierGridApi.value?.getFilterInstance(
    //   "type"
    // );
    // typeFilterComponent?.setModel(null);

    // const companyFilterComponent = selectSupplierGridApi.value!.getFilterInstance(
    //   "company"
    // );
    // companyFilterComponent?.setModel(null);

    // const ratingFilterComponent = selectSupplierGridApi.value!.getFilterInstance(
    //   "rating"
    // );
    // ratingFilterComponent?.setModel(null);

    selectSupplierGridApi.value!.setFilterModel(null);
  };

  onMounted(() => {
    setTimeout(() => {
      supplierList.value = SUPPLIER_LIST;
      flattenSupplierList.value = flattenArray();
      populateTypeMultiselect();
      populateCompanyMultiselect();
      selectSupplierLoading.value = false;
    }, 1500);
  });

  return {
    tagRef,
    ratingRef,
    companyRef,
    handleTagClose,
    selectedSuppliers,
    handleFilterApply,
    filterArrayValues,
    handleClearFilters,
    flattenSupplierList,
    selectSupplierTagMultiselect,
    selectSupplierRatingMultiselect,
    selectSupplierCompanyMultiselect,
    supplierList,
    selectSupplierGridApi,
    selectSupplierColApi,
    selectSupplierLoading,
    selectSupplierBodyOptions,
    selectSupplierCustomColumnDefs,
    selectSupplierHandleSupplierSelection,
    selectSupplierComponentKey,
    address,
  };
};
